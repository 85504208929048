import {gql} from "apollo-boost";

const getGlossaryTree = ({nodeUri, filter}) => {
    return {
        variables: {
            nodeUri: nodeUri,
            filter: filter
        },
        query: gql`query GetGlossaryTree(
            $nodeUri:String!,
            $filter:GlossaryNodeSearchFilter
        ){
            getGlossary(nodeUri:$nodeUri){
                nodeUri
                label
                readme
                created
                owner
                status
                path
                categories{
                    count
                    page
                    pages
                    hasNext
                    hasPrevious
                    nodes{
                        nodeUri
                        parentUri
                        label
                        readme
                        stats{
                            categories
                            terms
                        }
                        status
                        created
                    }
                }
                tree(filter:$filter){
                    count
                    hasNext
                    hasPrevious
                    page
                    pages
                    nodes{
                    __typename
                    ...on Glossary{
                        nodeUri
                        label
                        readme
                        created
                        owner
                        path                    
                    }
                    ...on Category{
                        nodeUri
                        label
                        parentUri
                        readme
                        created
                        owner
                        path
                    }
                    ...on Term{
                        nodeUri
                        parentUri
                        label
                        readme
                        created
                        owner
                        path
                    }
        
                    }
                }
            }
        }
        `
    }
}


export default getGlossaryTree;
