const PagedResponseDefault = {
    count:1,
    page:1,
    pages:1,
    hasNext:false,
    hasPrevious:false,
    nodes:[]
}

export default PagedResponseDefault
