const config = {
    apiGateway: {
        REGION: "eu-west-1",
        URL: "https://b3t0fbonwd.execute-api.eu-west-1.amazonaws.com/prod/graphql/api",
        ESURL: "https://b3t0fbonwd.execute-api.eu-west-1.amazonaws.com/prod/search/api",

    },
    cognito: {
        REGION: "eu-west-1",
        USER_POOL_ID: "eu-west-1_nGSjcpgeT",
        APP_CLIENT_ID: "6823ietcv2qf4ap7ho3igibags",
        DOMAIN: "datahubgammaeuwest1116231435750.auth.eu-west-1.amazoncognito.com",
        REDIRECT_URI: "https://d3p8ydekbfk4mm.cloudfront.net",
        //REDIRECT_URI: "https://localhost:8080",
        SIGNOUT_URL: "https://d3p8ydekbfk4mm.cloudfront.net",
        TYPE:"token",
        SCOPE:['email','phone','openid','profile']
    }

};
export default {
    ...config,
};
