

const FormFieldTypes={
    Input:"input",
    Tags:"tags",
    Select:"select",
    Term:'term',
    TextArea:"textarea",
    MultiSelect:"multiselect"
}

export default FormFieldTypes;
